<template>
  <div>

    <!--banner-->
    <div class="nban pc">
        <img src="img/y32.jpg">
        <div class="nbana">
            <h6>
                NEWS CENTER</h6>
            <h5>
                新闻中心</h5>
            <p>
                针对客户需求制定合理解决方案</p>
        </div>
    </div>
    <!--手机banner-->
    <div class="sjnban mob">
        <img src="img/y33.jpg">
    </div>
    <div class="neiye neiyea">
        <div class="bt bta animated bounceIn" data-scroll-reveal="enter bottom and move 30px over 0.3s" data-scroll-reveal-id="1" style="-webkit-transform: translatey(0);transform: translatey(0);opacity: 1;-webkit-transition: -webkit-transform 0.3s ease-in-out 0.1s,  opacity 0.3s ease-in-out 0.1s;transition: transform 0.3s ease-in-out 0.1s, opacity 0.3s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;" data-scroll-reveal-initialized="true">
            <img src="img/tt2.png">
            <span></span>
        </div>
        <div class="commen">
            <div class="xwl">
                <div class="xwla" v-for="item, index in listData" :key="index" data-scroll-reveal="enter bottom and move 30px over 0.3s" data-scroll-reveal-id="2" style="-webkit-transform: translatey(0);transform: translatey(0);opacity: 1;-webkit-transition: -webkit-transform 0.3s ease-in-out 0.1s,  opacity 0.3s ease-in-out 0.1s;transition: transform 0.3s ease-in-out 0.1s, opacity 0.3s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;" data-scroll-reveal-initialized="true">
                  <a :href="'article/'+item.id" :title="item.title">
                    <div class="xwla_t fl"><img style="max-height:274px" :src="baseUrl + item.coverImage" :alt="item.title"></div>
                    <div class="xwla_w fr">
                    <h6>{{item.title}}</h6>
                    <span>{{item.createTime}}</span>
                    <p>{{item.description}}</p>
                    <div class="xwla_m">查看更多 &gt;</div>
                    </div>
                    <div class="clearfix"></div>
                  </a>
                  </div>
            </div>
          <!-- <div class="ym yma" data-scroll-reveal="enter bottom and move 30px over 0.3s" data-scroll-reveal-id="12" style="-webkit-transform: translatey(30px);transform: translatey(30px);opacity: 0;-webkit-transition: -webkit-transform 0.3s ease-in-out 0s,  opacity 0.3s ease-in-out 0.1s;transition: transform 0.3s ease-in-out 0s,  opacity 0.3s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;" data-scroll-reveal-initialized="true">
            <p><a href="#">&lt;</a></p>
            <p class="on"><a href="#">1</a></p>
            <p><a href="#">2</a></p>
            <p><a href="#">3</a></p>
            <p><a href="#">&gt;</a></p>
            <div class="clearfix"></div>
          </div> -->

        </div>
    </div>
  </div>
</template>

<script>
import "@/assets/js/push.js";
import "@/assets/js/easing.min.js";
import "@/assets/js/scrollReveal.js";
import Swiper from "swiper/dist/idangerous.swiper.js";
import { listArticle, baseUrl } from '@/api/article'
export default {
    data () {
        return {
            listData:[],
            baseUrl:baseUrl
        }
    },
    created() {
        listArticle({articleType:1}).then( res=> {
           this.listData =  res.rows 
        })
    },
  
  mounted() {
			

	  $(function(){
		$(".yb_conct").hover(function() {
		$(".yb_conct").css("right", "5px");
		$(".yb_bar .yb_ercode").css('height', '190px');
		}, function() {
		$(".yb_conct").css("right", "-127px");
		$(".yb_bar .yb_ercode").css('height', '53px');
		});
		$(".yb_top").click(function() {
		$("html,body").animate({
		'scrollTop': '0px'
		}, 300)
		});
		});

    $(function () {
      $(".yb_conct").hover(
        function () {
          $(".yb_conct").css("right", "5px");
          $(".yb_bar .yb_ercode").css("height", "190px");
        },
        function () {
          $(".yb_conct").css("right", "-127px");
          $(".yb_bar .yb_ercode").css("height", "53px");
        }
      );
      $(".yb_top").click(function () {
        $("html,body").animate(
          {
            scrollTop: "0px",
          },
          300
        );
      });
    });

    (function ($) {
      "use strict";
      window.scrollReveal = new scrollReveal({ reset: true, move: "50px" });
   

    var swiper2 = new Swiper(".swiper2", {
      autoplay: {
        delay: 6000,
        disableOnInteraction: false,
      },
      speed: 600, //切换速度
      autoHeight: true, //高度随内容变化
      loop: true,
    });
	console.log(swiper2);
    var swiper_yw = new Swiper("#swiper_yw", {
      autoplay: {
        delay: 8000,
        disableOnInteraction: false,
      },
      speed: 500, //切换速度
      autoHeight: true, //高度随内容变化
      loop: true,
      spaceBetween: "2%",
      slidesPerView: 2,
    });

    var swiper_xw = new Swiper("#swiper_xw", {
      autoplay: {
        delay: 8000,
        disableOnInteraction: false,
      },
      speed: 500, //切换速度
      autoHeight: true, //高度随内容变化
      loop: true,
      spaceBetween: "2%",
      slidesPerView: 2,
    });
	 })();


  },
};

  var mySwiper = new Swiper('.swiper-container',{
    loop: true
    //其他设置
  });  
</script>

<style scoped>
@import "~@/assets/style/yc.css";
@import "~@/assets/style/swiper.min.css";
@import "~@/assets/style/animate.min.css";
</style>