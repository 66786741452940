<template>
  <div>

    <!--banner-->
    <div class="nban pc">
        <img src="img/y21.jpg">
        <div class="nbana">
            <h6>
                PRODUCTS &amp; SOLUTIONS</h6>
            <h5>
                主营业务</h5>
            <p>
                针对客户需求制定合理解决方案</p>
        </div>
    </div>
    <!--手机banner-->
    <div class="sjnban mob">
        <img src="img/y27.jpg">
    </div>
    <div class="neiye">
        <div class="zyla">
            <div class="zylax">
                <div :class="item.class" v-for="item,index in menuData"  @click="nav(item.articleClassification)" :key="index"><a href="#" :title="item.name"><h1>{{item.name}}</h1></a></div>
                <div class="clearfix">
                </div>
            </div>
        </div>
        <div class="commen">
            <div class="zylb">
                <div v-for="item in listData" :key="item.id" class="zylba fl zylbaa" data-scroll-reveal="enter bottom and move 30px over 0.3s" data-scroll-reveal-id="1" style="-webkit-transform: translatey(0);transform: translatey(0);opacity: 1;-webkit-transition: -webkit-transform 0.3s ease-in-out 0.1s,  opacity 0.3s ease-in-out 0.1s;transition: transform 0.3s ease-in-out 0.1s, opacity 0.3s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;" data-scroll-reveal-initialized="true">
                    <a :href="'business/'+item.id" :title="item.title">
                    <div class="zylba_t"><img style="max-height:274px" :src="baseUrl + item.coverImage" :alt="item.title"></div>
                    <div class="zylba_w">
                    <p><img src="img/y5-1.png">{{item.title}}</p>
                    <img src="img/y31.png">
                    <div class="clearfix"></div>
                    </div>
                    </a>
                </div>
                <div class="clearfix">
                </div>
            </div>
        </div>
    </div>

  </div>
</template>

<script>
import { listArticle, baseUrl } from '@/api/article'
export default {
    data () {
        return {
            menuData: [
                { name:'系统集成', class:'zyla_a2 on' ,articleClassification:0},
                { name:'软件开发', class:'zyla_a2' ,articleClassification:1},
                { name:'智慧校园运营', class:'zyla_a2',articleClassification:2},
            ],
            listData:[],
            baseUrl:baseUrl

        }
    },
    created() {
        listArticle({articleType:2}).then( res=> {
           this.listData =  res.rows 
        })
    },
    methods: {
        nav(articleClassification) {
            listArticle({articleType:2,articleClassification:articleClassification}).then( res=> {
                this.listData =  res.rows 
            })
            this.menuData.forEach(i=> {
            if(i.articleClassification == articleClassification) {
                i.class = 'zyla_a2 on'
            } else {
                i.class = 'zyla_a2'
            }
            })
        },
        
    },
    mounted() {

    }
}
</script>

<style>

</style>