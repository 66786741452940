import request from '@/utils/request'
export let baseUrl = 'http://120.25.76.141:8083'
 
// 查询文章列列表
export function listArticle(query) {
  return request({
    url: baseUrl+'/article/articleT/list',
    method: 'get',
    params: query
  })
}

// 查询文章列详细
export function getArticle(id) {
  return request({
    url: baseUrl+'/article/articleT/' + id,
    method: 'get'
  })
}

// 新增文章列
export function addArticle(data) {
  return request({
    url: baseUrl+'/article/articleT',
    method: 'post',
    data: data
  })
}

// 修改文章列
export function updateArticle(data) {
  return request({
    url: baseUrl+'/article/articleT',
    method: 'put',
    data: data
  })
}

// 删除文章列
export function delArticle(id) {
  return request({
    url: baseUrl+'/article/articleT/' + id,
    method: 'delete'
  })
}

// 导出文章列
export function exportArticle(query) {
  return request({
    url: baseUrl+'/article/articleT/export',
    method: 'get',
    params: query
  })
}