<template>
  <div>
    <!--banner-->
    <div class="nban pc">
        <img src="/img/y21.jpg">
        <div class="nbana">
            <h6>
                PRODUCTS &amp; SOLUTIONS</h6>
            <h5>
                主营业务</h5>
            <p>
                针对客户需求制定合理解决方案</p>
        </div>
    </div>
    <!--手机banner-->
    <div class="sjnban mob">
        <img src="/img/y33.jpg">
    </div>
    <div class="neiye neiyea">
        <div class="bt bta animated bounceIn" data-scroll-reveal="enter bottom and move 30px over 0.3s" data-scroll-reveal-id="1" style="-webkit-transform: translatey(0);transform: translatey(0);opacity: 1;-webkit-transition: -webkit-transform 0.3s ease-in-out 0.1s,  opacity 0.3s ease-in-out 0.1s;transition: transform 0.3s ease-in-out 0.1s, opacity 0.3s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;" data-scroll-reveal-initialized="true">
            <img src="/img/tt1.png">
            <span></span>
        </div>
        <div class="commen">
            <div class="xwn">
                <div class="xwna" data-scroll-reveal="enter bottom and move 30px over 0.3s" data-scroll-reveal-id="2" style="-webkit-transform: translatey(0);transform: translatey(0);opacity: 1;-webkit-transition: -webkit-transform 0.3s ease-in-out 0.1s,  opacity 0.3s ease-in-out 0.1s;transition: transform 0.3s ease-in-out 0.1s, opacity 0.3s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;" data-scroll-reveal-initialized="true">
                    <h1>
                        {{articleData.title}}</h1>
                </div>
                <div class="xwnb" v-html="articleData.content">
                </div>
                <div class="xwnc">
	<div class="bk1a fl">
		<h5>
			专业
		</h5>
		<h6>
			major
		</h6>
		<p>
			专注于数据中心、融合通讯<br>
和智慧建筑等专业领域
		</p>
	</div>
	<div class="bk1a fl bk1ac">
		<h5>
			团队
		</h5>
		<h6>
			team
		</h6>
		<p>
			一支具有专业知识<br>
丰富经验的IT团队
		</p>
	</div>
	<div class="bk1a fl bk1ab">
		<h5>
			经验
		</h5>
		<h6>
			experience
		</h6>
		<p>
			公司成立于2010年<br>
拥有行业内十余年解决方案经验
		</p>
	</div>
	<div class="bk1a fl bk1aa">
		<h5>
			服务
		</h5>
		<h6>
			service
		</h6>
		<p>
			坚持以客户为中心的服务理念<br>
助力客户加速创新与发展
		</p>
	</div>
	<div class="clearfix">
	</div>
</div>
            </div>
            <div class="fhlb" data-scroll-reveal="enter bottom and move 30px over 0.3s" data-scroll-reveal-id="3" style="-webkit-transform: translatey(30px);transform: translatey(30px);opacity: 0;-webkit-transition: -webkit-transform 0.3s ease-in-out 0s,  opacity 0.3s ease-in-out 0.1s;transition: transform 0.3s ease-in-out 0s,  opacity 0.3s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;" data-scroll-reveal-initialized="true">
                <a href="articlelist">返回列表 &gt;</a>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { getArticle, baseUrl } from '@/api/article'
export default {
  data () {
    return {
      baseUrl:baseUrl,
      articleData: {
        title:'',
        author:'',
        content:'',
        createTime:'',
      }
    }
  },
  created() {
    let id = this.$route.params.id
    if(id) {
      getArticle(id).then( res=> {
        this.articleData = res.data
      })
    }
  }
};
</script>

<style>

</style>